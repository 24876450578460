.calculator-container {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f8f9fa;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }
  
  h1 {
    text-align: center;
    margin-bottom: 20px;
    font-size: 1.8em;
    color: #333;
  }
  
  .input-group {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
  }
  
  .input-group label {
    flex: 1;
    margin-right: 10px;
    font-weight: bold;
    font-size: 1em;
  }
  
  .input-group select,
  .input-group input {
    flex: 2;
    padding: 8px;
    border-radius: 4px;
    border: 1px solid #ccc;
    font-size: 1em;
  }
  
  .calculate-btn {
    display: block;
    width: 100%;
    padding: 10px;
    background-color: #28a745;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 1.2em;
    cursor: pointer;
  }
  
  .calculate-btn:hover {
    background-color: #218838;
  }
  
  ul {
    list-style-type: none;
    padding: 0;
  }
  
  ul li {
    padding: 5px 0;
    font-size: 1.1em;
  }
  